import React, { createContext, lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import OrderSuccess from "./auth/OrderSuccess";
import * as queries from "../../operations/queries";
import { useQuery } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import i18n from "../../i18n";
import axios from "axios";
import NotFoundPage from "../../components/404page/NotFoundPage";
import Loader from "./Layout/loader";

const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ "./dashboard"));
const Checkout = lazy(() => import(/* webpackChunkName: "Dashboard" */ "./auth/checkout"));
const RegSuccess = lazy(() => import(/* webpackChunkName: "Dashboard" */ "./auth/RegSuccess"));

export type ICountryContext = {
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
};

export const CountryContext = createContext({
  country: "ae_ar",
  setCountry: (country: string) => {},
  store: "",
  setStore: (store: string) => {},
  loading: true,
  setLoading: (loading: boolean) => {},
  currentStore:"",
});
export const LoadingContext = createContext({
  Loading: true,
  setLoading: (loading: boolean) => {},
});

export const mainContext = createContext({
  cartData: [],
  setCartData: (data: any) => {},
  cartCount: 0,
  setCartCount: (count: any) => {},
  quest_cart_id: null,
  set_quest_cart_id: (id: any) => {},
  cartAdded: false,
  setCartAdded:(id: any) => {},
  cartId:"",
  setCartId:(count: any) => {}
});
function MainComponent() {
  const storePath = window.location.pathname;
  const selectedStores = storePath?.split("/")?.[1];
  const [country, setCountry] = useState("ae_ar");
  const [store, setStore] = useState("");
  const [loading, setLoading] = useState(true);
  const [cartData, setCartData] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [cartId, setCartId] = useState("");
  const [cartAdded, setCartAdded] = useState<any>(false);
  const sessionStoreData = sessionStorage.getItem("storeAndCart");
  const loggedInToken = localStorage.getItem("loggedInCust");
  const currentsessionStore = sessionStoreData
    ? JSON.parse(sessionStoreData)
    : [];
    // const storeCode:any = localStorage.getItem('store_code');

  // eslint-disable-next-line
  const filteredArray =
    currentsessionStore &&
    currentsessionStore?.filter(
      (object: any) => object.genStore === selectedStores
    );
  const [quest_cart_id, set_quest_cart_id] = useState<any>();

  const {
    // eslint-disable-next-line
    loading: custlload,
    data: custQueryDataRes,
    refetch: custCartQueryDataRefetch,
  } = useQuery(queries.customerQueries.GET_CUSTOMER_CART_QUERY, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });
  

  const {
    data: custWishlistData,
  } = useQuery(queries.wishlistQueries.GET_CUSTOMER_WISHLISTS, {
    skip: !loggedInToken,
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  if(custWishlistData && custWishlistData?.customer?.wishlists[0]?.id !== "undefined"){
    const wishlistId = custWishlistData?.customer?.wishlists[0]?.id;
      localStorage.setItem("wishlistId", wishlistId);
  }

  useEffect(() => {
    custCartQueryDataRefetch();
  }, [loggedInToken, custCartQueryDataRefetch]);
 
  useEffect(() => {
    if (
      loggedInToken !== null &&
      loggedInToken !== undefined &&
      loggedInToken !== ""
    ) {
      set_quest_cart_id(custQueryDataRes?.customerCart?.id);
    } else if (loggedInToken === "" || currentsessionStore.length > 0) {
      const filteredArray =
        currentsessionStore &&
        currentsessionStore?.filter(
          (object: any) => object.genStore === selectedStores
        );
      if (filteredArray[0]) {
        set_quest_cart_id(filteredArray[0].sessionGenCartId);
      }
    }
    // eslint-disable-next-line
  }, [loggedInToken, currentsessionStore, custQueryDataRes]);

  let storeLang: string = "";
  let countrys: string = "";
  const [currentStore, setCurrentStore] = useState("");


  useEffect(() => {
    const path = window.location.pathname;
    const selecteStore = path?.split("/");
    if (selecteStore[1] === "in") {
        // eslint-disable-next-line
      storeLang = "in";
      // eslint-disable-next-line
      countrys = "in";
    } else if (selecteStore[1] === "ae_en") {
      storeLang = "ae_en";
      countrys = "ae_ar";
    } else if (selecteStore[1] === "ae_ar") {
      storeLang = "ae_ar";
      countrys = "ae_ar";
    } else if (selecteStore[1] === "sa_ar") {
      storeLang = "sa_ar";
      countrys = "sa_en";
    } else if (selecteStore[1] === "sa_en") {
      storeLang = "sa_en";
      countrys = "sa_en";
    } else if (selecteStore[1] === "ar") {
      storeLang = "ar";
      countrys = "en";
    } else if (selecteStore[1] === "en") {
      storeLang = "en";
      countrys = "en";
    } else {
      axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          if (
            data?.country_code === "UA" ||
            data?.country_code === "ARE" ||
            data?.country_code === "AE"
          ) {
            storeLang = "ae_en";
            countrys = "ae_ar";
          } else if (
            data?.country_code === "SD" ||
            data?.country_code === "SAU" ||
            data?.country_code === "SA"
          ) {
            storeLang = "sa_ar";
            countrys = "sa_en";
          } else if (
            data?.country_code === "OM" ||
            data?.country_code === "OMN" ||
            data?.country_code === "OM"
          ) {
            storeLang = "ar";
            countrys = "en";
          } else if (
            data?.country_code === "IN" ||
            data?.country_code === "IND"
          ) {
            storeLang = "in";
            countrys = "in";
          } else {
            storeLang = "ae_en";
            countrys = "ae_ar";
          }
          // storeLang = "in";
          // country = "in";
          setStore(storeLang);
          setCountry(country);
          setCurrentStore(storeLang);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setStore(storeLang);
    setCountry(countrys);
    setCurrentStore(storeLang);
  }, []);


  useEffect(() => {
    if (store && store !== "" && store !== undefined) {
      if (store === "en" || store.split("_")[1] === "en" || store === "in") {
        let beforeVal: any;
        if (store.indexOf("_") !== -1) {
          beforeVal = store.split("_")[0] + "_ar";
        } else {
          // eslint-disable-next-line
          beforeVal = "en";
        }
        i18n.changeLanguage("en");
        document.documentElement.setAttribute("lang", "en");
        document.body.classList.add("en");
        document.body.classList.remove("ar");
      } else {
        let beforeVal: any;
        if (store.indexOf("_") !== -1) {
          beforeVal = store.split("_")[0] + "_en";
        } else {
          // eslint-disable-next-line
          beforeVal = "ar";
        }
        i18n.changeLanguage("ar");
        document.documentElement.setAttribute("lang", "ar");
        document.body.classList.add("ar");
        document.body.classList.remove("en");
      }
    }
  }, [store]);

 

  return (
    // <BrowserRouter basename="/jebelz-ui"></BrowserRouter>
    <CountryContext.Provider
      value={{ country, setCountry, store, setStore, loading, setLoading,currentStore }}
    >
      <mainContext.Provider
        value={{
          cartData,
          setCartData,
          cartCount,
          setCartCount,
          quest_cart_id,
          set_quest_cart_id,
          cartAdded,
          setCartAdded,
          cartId,
          setCartId
        }}
      >
        <BrowserRouter basename="/">
          <ToastContainer position="top-right" autoClose={3000} rtl={store === "ae_ar" || store === "ar" || store === "sa_ar" ? true : false} />
          <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/regsuccess" element={<RegSuccess />} />
            <Route path="/*" element={<Dashboard />} />
            <Route path="/:country/checkout" element={<Checkout />} />
            <Route
              path="/:country/checkout/?paymentFail=true"
              element={<Checkout />}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          </Suspense>
        </BrowserRouter>
      </mainContext.Provider>
    </CountryContext.Provider>
  );
}

export default MainComponent;
